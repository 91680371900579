<script setup>
  import { ref } from 'vue';
  import { useBreakpoints } from '$storefront';

  const props = defineProps({
    product: Object,
    sections: Array,
    recommended: Array,
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();

  const parameters = ref({
    navigation: true,
    spaceBetween: 24,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <div v-if="sections.length > 0 || product.list_attributes.length > 0 || product.reviews_average > 0" class="container product-description">
    <div class="wrapper product-description__content">
      <ShopProductViewDescriptionSection v-if="product.list_attributes.length > 0" id="specification" :title="$t('Specyfikacja')" class="product-description--margin-scroll">
        <ShopProductViewDescriptionSpecification :product="product" />
      </ShopProductViewDescriptionSection>
      <ShopProductViewDescriptionSection v-if="sections.length > 0" id="description" :title="$t('Opis')" class="product-description--margin-scroll">
        <SectionRenderer :sections="sections" />
      </ShopProductViewDescriptionSection>
      <ShopProductViewDescriptionSection v-if="recommended.length > 0" id="recommended" :title="$t('Polecamy')" class="product-description--margin-scroll">
        <div class="product-description__products">
          <SfProductsCarousel :parameters :products="recommended" />
        </div>
      </ShopProductViewDescriptionSection>
      <ShopProductViewDescriptionSection id="reviews" :title="$t('Opinie klientów')" class="product-description--margin-scroll">
          <ShopProductViewDescriptionReviews :product="product" :reviews="reviews" :reviewAvailable :reviewsSummary />
      </ShopProductViewDescriptionSection>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .opinions-counter {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 0.25rem;

    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
    }

    &__item-star-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      min-width: 2rem;
      font-size: 0.875rem;
      font-weight: var(--font-weight-500);
    }

    &__progress-value {
      height: 100%;
      background-color: var(--primary-color);
      border-radius: 0.5rem;
    }

    &__progress {
      flex-grow: 1;
      height: 0.5rem;
      border-radius: 0.5rem;
      background-color: var(--color-medium-gray);
    }

    &__count {
      min-width: 2rem;
      text-align: right;
      font-size: 0.875rem;
      color: var(--color-primary-gray);
    }
  }

  .product-description {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    margin-bottom: 4rem;

    &__info {
      text-align: center;
    }

    &__nav {
      margin-bottom: 3rem;
      position: sticky;
      top: 90px;
      z-index: 999;
    }

    &__nav--hidden {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
      background-color: var(--color-white);
    }

    &__products {
      --sf-carousel-slide-padding-x: 0.375rem;
      --sf-carousel-slide-padding-y: 0.375rem;
    }

    &__stars {
      --sf-stars-padding-y: 0;
      --sf-stars-padding-x: 0;

      display: flex;
      align-items: center;
      column-gap: 0.75rem;
    }

    &__review-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3rem;
      align-items: center;
    }

    &__rate {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      font-weight: var(--font-weight-700);
      --sf-btn-font-size: 1.25rem;
    }

    &--margin-scroll {
      scroll-margin-top: 160px;
    }

    &__nav {
      font-size: 1rem;
      padding: 1rem 2.5rem;
    }

    :deep(#specification) {
      .product-section {
        &__header {
          background-color: var(--primary-color);
          padding: 0.75rem 1rem;
          margin-bottom: 0;
        }

        &__heading {
          font-size: 1rem
        }
      }
    }

    #description {
      --sf-section-renderer-gap: 1.5rem;

      :deep(.sections-wrapper) {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .section-typography h1,
        .section-typography h2,
        .section-typography h3,
        .section-typography h4,
        .section-typography h5,
        .section-typography h6 {
          font-size: 1.25rem;
        }

        .text {
          gap: 0.75rem
        }

        .section-wrapper {
          padding: 0;
          gap: 1rem;
        }
      }

      :deep(.download) {
        padding: 1rem 0;

        .download__header {
          font-size: 1.125rem;

          &::after {
            display: none;
          }
        }

        .download__file {
          background-color: var(--color-primary-white);

          &:nth-child(4n+1),
          &:nth-child(4n+2) {
            background-color: var(--color-light-gray);
          }
        }

        @media(max-width: 992px) {
          padding: 0;

          .download__file {
            &:nth-child(4n+1),
            &:nth-child(4n+2) {
              background-color: var(--color-primary-white);
            }

            &:nth-child(odd) {
              background-color: var(--color-primary-body-background);
            }
          }
        }
      }
    }
  }

  @media(max-width: 992px) {
    .product-description {
      gap: 1.5rem;

      &__content {
        padding: 1rem 0;
        gap: 2rem;
      }

      &__header {
        padding: 0.75rem;
      }


      &__stars {
        margin-bottom: 1rem;
      }

      &__review-header {
        --sf-btn-padding-x: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      &__rate {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        flex-wrap: wrap;
        font-weight: var(--font-weight-500);
        --sf-btn-font-size: 1rem;
      }

      :deep(#specification) {
        .product-section {
          &__heading {
            font-size: 1.25rem
          }
        }
      }

      #description {
        --sf-section-renderer-paragraph-font-size: 0.875rem;
        padding: 0 1rem;

        :deep(.sections-wrapper) {

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          .section-typography h1,
          .section-typography h2,
          .section-typography h3,
          .section-typography h4,
          .section-typography h5,
          .section-typography h6 {
            font-size: 1rem;
          }

          .section-wrapper {
            gap: 0.5rem;
          }
        }

        :deep(.photo-text, .text-photo) {
          padding: 0;
        }

        :deep(.download) {
          .download__content > p {
            font-size: 0.875rem;
          }

          .download__header {
            font-size: 1rem;
            font-weight: var(--font-weight-600);
          }

          .download__file {
            padding-left: 0;
            padding-right: 0;
          }

        }
      }

      #recommended {
        padding: 0 0 0 1rem;

        :deep(.product-section__header) {
          .product-section__heading {
            font-size: 1rem;
          }
        }
      }

      :deep(#specification) {
        .product-section {
          &__header {
            background-color: unset;
          }
        }
      }

      #reviews {
        padding: 0 1rem;

        :deep(.product-section__header) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media(max-width: 768px) {
    .stars {
      --sf-stars-star-size: 1rem
    }
  }
</style>
