<script lang="ts" setup>
  import { useBreakpoints } from '$storefront';
  import type { PropType } from 'vue';

  type BlogPost = {
    photo: string | null;
    title: string | null;
    description_short: string | null;
    category_title: string | null;
    date: string | null;
    administrator_name: string | null;
  }

  defineProps({
    post: { type: Object as PropType<BlogPost>, required: true },
    horizontal: { type: Boolean, required: false, default: false },
    header: { type: String, required: false, default: 'h3' },
  });

  const breakpoints = useBreakpoints();
</script>
<template>
  <VisitorLink :class="{ horizontal }" :href="route('web.blog.post.show', { post })" class="article-link-wrapper">
    <div class="article-wrapper">
      <div class="image-wrapper">
        <MediaPicture :photo="post.photo" sizes="650x650 2x, 325x325, 1236x1236 2x max 992, 618x618 max 992" />
      </div>
      <div class="content-wrapper">
        <div class="content">
          <component :is="header" class="title">
            {{ post.title }}
          </component>
          <p class="description" v-html="post.description_short" />
          <div class="article__meta-data" v-if="horizontal">
            <div v-if="post.administrator_name" class="article__meta-data-item">
              <IconPersonRolodex />
              <span>
                {{ $t('Autor') + ': ' }}
                {{ post.administrator_name }}
              </span>
            </div>
            <span v-if="post.category_title" :title="post.category_title" class="article__meta-data-item">
              <IconLightning />
              <span class="article__meta-data-category">
                {{ post.category_title }}
              </span>
            </span>
            <span v-if="post.date" class="article__meta-data-item">
              <IconCalender />
              <SfDateTime :value="post.date" />
            </span>
          </div>
        </div>
        <SfButton class="content-btn" :primary="!breakpoints.lg">
          <template #default>
            {{ $t('Czytaj dalej') }}
          </template>
          <template #after>
            <SfIconBook />
          </template>
        </SfButton>
      </div>
    </div>
  </VisitorLink>
</template>
<style lang="scss" scoped>
  .article-link-wrapper {
    width: 100%;
    background-color: var(--color-white);
    transition-property: box-shadow;
    transition-duration: var(--transition-primary-duration);
    transition-timing-function: var(--transition-primary-timing-function);

    .article-wrapper {
      display: flex;
      position: relative;
      color: var(--color-main-black);
      transition: var(--transition-primary);

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem 2.5rem 2rem 2rem;
        background-color: var(--color-light-gray);
      }

      .image-wrapper {
        flex: 0 0 50%;
        max-width: 325px;

        :deep(picture) {
          img {
            height: 100%;
            aspect-ratio: 1;
            object-fit: cover;
          }
        }
      }

      .title {
        font-size: 1rem;
        font-weight: var(--font-weight-700);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
      }

      .description {
        font-size: 0.75rem;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      .content-btn {
        align-self: flex-end;
        width: auto;
      }

      .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 1rem;

        .article__meta-data {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          .article__meta-data-item {
            font-size: 0.875rem;
            gap: 0.5rem;
            display: flex;

            .article__meta-data-category {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            svg {
              height: 21px;
            }
          }
        }
      }
    }

    &.horizontal {

      .content-wrapper {
        background-color: var(--color-white);
      }
    }

    @media(hover: hover) {
      &:hover {

        &.horizontal {
          box-shadow: var(--box-shadow-primary);
        }

        .title {
          color: var(--primary-color-hover);
        }
      }
    }
  }

  @media (min-width: 993px) and (max-width: 1400px) {
    .article-link-wrapper .article-wrapper .image-wrapper {
      flex: 0 0 30%;
    }
  }

  @media (max-width: 576px) {
    .article-link-wrapper {

      .article-wrapper {
        flex-direction: column;

        .content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          padding: 0.75rem 0 0;
          background-color: var(--color-white);

          .content-btn {
            width: 100%;
          }
        }

        .image-wrapper {
          flex: unset;
          width: 100%;
          max-width: unset;

          :deep(picture) {
            img {
              height: unset;
            }
          }
        }

        .title {
          font-size: 0.875rem;
          font-weight: var(--font-weight-500);
        }

        .description {
          font-size: 0.75rem;
        }
      }
    }
  }
</style>
