<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data).then(() => {
      signUpEmail.value = true;
    });
  }

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <div class="newsletter">
    <header>
      <SegmentHeading larger type="h2" class="newsletter__heading">
        {{ $t('Newsletter') }}
        <template #icon>
          <IconEnvelope />
        </template>
      </SegmentHeading>
      <p class="newsletter__text">
        {{ $t('Otrzymuj kody rabatowe, aktualności dotyczące promocji oraz informacje o najnowszych produktach.') }}
      </p>
    </header>
    <template v-if="!signUpEmail">
      <div>
        <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
          <div class="newsletter__content">
            <SfFormInput :label="$t('E-mail')" name="email" required />
            <SfButton primary :loading="submitting" type="submit">
              {{ $t('Zapisz się') }}
            </SfButton>
          </div>
          <div class="newsletter__consent">
            <SfFormSwitch name="acceptance_rodo" required>
              <span v-html="privacyPolicy" />
            </SfFormSwitch>
          </div>
        </VisitorForm>
      </div>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </div>
</template>
<style lang="scss">
  .newsletter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 3rem;
    padding: 100px 0;
    max-width: 1400px;
    width: 98%;
    margin: 0 auto;
    color: var(--color-primary-white);

    .section-title {
      color: var(--color-primary-white);
    }

    &__text {
      margin-top: 2rem;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 1.75rem;
      margin-top: 3rem;
    }

    &__consent {
      --sf-switch-label-color: var(--color-white);
      padding-top: 1.5rem;

      a {
        color: var(--color-white);
        text-decoration: underline;
        transition: color 0.2s ease-out;
        @media(hover: hover) {
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    .subscribed {
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media (max-width: 992px) {
    .newsletter {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      padding: 2.5rem 1rem;
      margin: 0 auto;
      width: 100%;

      &__text {
        margin-top: 1rem;
      }

      &__content {
        flex-direction: column;
        gap: 1rem;
        margin-top: 0;
      }

      &__consent {
        padding-top: 1rem;
      }
    }
  }

  @media (max-width: 576px) {
    .newsletter__content {
      .btn {
        width: 100%;
      }
    }
  }
</style>

