<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { useShared, route } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';

  defineProps({
    homepage: Object,
  });

  const headerBottom = ref();

  const scroll = ref(false);
  const show = ref(false);
  const showMenu = ref(false);
  const searchMobilebar = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  useScrollDetector({
    threshold: 8,
    callback: ({ up }) => {
      if (headerBottom.value) {
        if (up) {
          headerBottom.value.classList.add('header__bottom--open');
          scroll.value = false;
        } else {
          headerBottom.value.classList.remove('header__bottom--open');
          scroll.value = true;
        }
      }
    },
  });

  watch(scroll, () => {
    if (!scroll.value) {
      show.value = false;
      searchMobilebar.value = false;
    }
  });
</script>
<template>
  <header :class="{ 'header--scroll': scroll, 'search-mobile-active': searchMobilebar }" class="header">
    <div class="header__top">
      <div class="container header__top__container">
        <div class="header__top__logo-wrapper">
          <VisitorLink :href="route('web.homepage.index')" class="header__top__logo" target="_self">
            <SfLogo class="header__top__logo__img" />
          </VisitorLink>
        </div>
        <HeaderSearch v-if="breakpoints.lg" :input="breakpoints.lg" class="header__top__search" @click="openSearch = true" />
        <div class="header__top__icons">
          <HeaderFavorite v-if="breakpoints.lg" :class="{ scroll }" />
          <HeaderAccount v-if="breakpoints.lg" :class="{ scroll }" />
          <MiniCart :class="{ scroll }" />
          <VatSwitcher class="header__top__icons__vat" />
        </div>
      </div>
    </div>
    <div ref="headerBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom__inner">
        <div class="header__bottom__inner__wrapper">
          <div class="header__bottom__inner__wrapper__content">
            <IconHamburger v-if="!breakpoints.lg" class="icon-hamburger" @click="showMenu = true" />
            <HeaderSearch v-if="!breakpoints.lg" input class="header__bottom__inner__wrapper__content__search" :placeholder="$t('Szukaj')"/>
            <Categories v-if="breakpoints.lg" compact />
            <nav v-if="breakpoints.lg" class="navigation">
              <ul>
                <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
  <Transition name="fade-down">
    <div v-if="!breakpoints.lg && scroll && searchMobilebar" class="mobile-search">
      <HeaderSearch input />
    </div>
  </Transition>
  <MenuDrawer v-if="!breakpoints.lg" v-model:open="showMenu" />
</template>
<style lang="scss" scoped>
  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .icon-hamburger {
    font-size: 1.5rem;
  }

  .fade-down-enter-active,
  .fade-down-leave-active {
    transition: var(--transition-primary-fade);
  }

  .fade-down-enter-from,
  .fade-down-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }

  .search-mobile-active.header {
    box-shadow: none;
  }

  .mobile-search {
    position: fixed;
    top: 49px;
    width: 100%;
    z-index: 997;
    padding: 5px 15px 10px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
  }

  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
    transition: var(--transition-header-scroll);

    --sf-product-price-current-font-size: 1.25rem;
    --sf-search-results-wrapper-padding: 4rem 1rem 1rem 1rem;

    &__line {
      display: none;
    }

    &__top {
      background-color: var(--color-light-gray);

      &__container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        width: 98%;
        margin: 0 auto;
        height: 90px;
        align-items: center;
        column-gap: 3rem;
        padding: 1.5rem 0;
      }

      &__logo__img {
        max-width: 260px;
      }

      &__icons {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-end;
        column-gap: 1.5rem;
        height: 100%;

        :deep(svg) {
          transition: var(--transition-primary);
        }

        &__vat {
          width: 119px;

          :deep(.switch__wrapper) {
            flex-direction: row;

            .switch__label {
              white-space: nowrap;
              --sf-switch-label-color: var(--color-main-black);
            }
          }
        }
      }
    }

    &__bottom {
      &__inner {
        width: 98%;
        margin: 0 auto;
      }
    }
  }

  @media(max-width: 1400px) {
    .header {
      &__top {
        &__icons {
          column-gap: 1.25rem;
        }
      }

      :deep(.search__buttons) {
        flex-direction: column;
      }
    }
  }

  @media(max-width: 1200px) {
    .header {
      &__top {
        &__logo, &__icons {
          width: auto;
        }
      }
    }
  }

  @media (min-width: 993px) {
    :deep( .search ) {
      .search__content {
        margin-top: 0;
      }

      .search__results-wrapper {
        top: -0.875rem;
      }
    }
  }

  @media (max-width: 992px) {
    .header {
      box-shadow: var(--box-shadow-primary);
      background-color: var(--color-light-gray);

      --sf-product-price-current-font-size: 1rem;

      &__top {
        &__container {
          display: flex;
          gap: 1.5rem;
          height: 50px;
          width: 100%;
          padding: 0.625rem 1rem 0.5rem;
        }

        &__logo {
          height: 100%;
          display: inline-block;

          &__img {
            width: 140px;
            max-width: 100%;
          }
        }

        &__logo-wrapper {
          height: 30px;
          margin-right: auto;
          max-width: 45%;
        }

        &__icons {
          width: auto;
          gap: 1rem;
          flex: 0 0 auto;

          &__vat {
            --sf-switch-font-size: 0.75rem;
            --sf-switch-line-height: 1.125rem;
            width: 93px;
            order: -1;
          }
        }

        &__search {
          width: auto;
        }
      }
    }
  }

  .header__bottom {
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease-in-out 100ms;
    background-color: var(--color-light-gray);

    @media(max-width: 992px) {
      border-top: none;
    }

    .icon-hamburger {
      flex: 0 0 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--open {
      grid-template-rows: 1fr;

      .header__bottom__inner {
        animation: show-overflow ease-in-out 100ms;
        overflow: visible;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      width: 98%;
      margin: 0 auto;
      min-width: 0;
      overflow: hidden;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-width: 0;

        &__content {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          width: 100%;

          &__search {
            flex-grow: 1;
          }
        }

        @media(max-width: 992px) {
          align-items: center;
          gap: 20px;
          padding: 0.5rem 1rem 0.625rem;
        }
      }
    }
  }

  .navigation {
    position: relative;
    width: 100%;
    padding-left: 2rem;

    ul {
      display: flex;
      gap: 1rem;
      padding: 0;
      margin: 0;
      list-style: none;


      li.nav-item {
        position: relative;

        :deep(.nav-item-arrow) {
          transform: rotate(0);
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }

        :deep(> .children-wrapper) {
          top: 100%;
          left: 0;
          right: unset;
          height: auto;
          padding: 0.5rem;
          min-width: 300px;
          border-radius: 0;

          a {
            font-weight: var(--font-weight-500);
            padding: 0.5rem 0;
          }

          .nav-item.level-2 .children-wrapper {
            display: flex;
            flex-direction: column;
          }

          .children {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-left: 1rem;
          }

          @media(max-width: 1200px) {
            left: unset;
            right: 0;
          }
        }
      }
    }

    :deep(.level-0) {
      & > a {
        text-decoration: none;
        padding: 1rem 0.375rem;
        color: var(--color-main-black);
        font-weight: var(--font-weight-700);
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
        z-index: 2;

        &:not(.active):hover {
          color: var(--primary-color-hover);
        }

        &.active {
          position: relative;
          display: inline-block;
          color: var(--primary-color);
          font-weight: var(--font-weight-700);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .navigation {
      padding-left: 10px;

      ul {
        gap: 14px;
        font-size: 15px;
      }
    }

    .header__bottom__inner {
      width: 100%;
    }
  }
</style>
