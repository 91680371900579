<script lang="ts" setup>
  import { ref, onMounted, type Ref, onBeforeUnmount, onUpdated, type PropType } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  type Category = {
    title: string;
    photo: string;
  }

  defineProps({
    categories: { type: Array as PropType<Category[]>, required: true },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      spaceBetween: 20,
      breakpoints: {
        1: {
          slidesPerView: 1.75,
          spaceBetween: 10,
        },
        576: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 5,
        },
      },
      modules: [Navigation],
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel categories-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container categories-carousel__container">
      <div class="swiper-wrapper">
        <template v-for="category in categories">
          <div class="swiper-slide items-carousel__slide  categories-carousel__article" v-if="category.photo">
            <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
              <MediaPicture :photo="category.photo" sizes="120x120 2x, 60x60" class="categories-carousel__image" />
              <span>
                {{ category.title }}
              </span>
            </VisitorLink>
          </div>
        </template>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style scoped lang="scss">
  .categories-carousel {
    &__link {
      position: relative;
      display: flex;
      gap: 1rem;
      flex-grow: 1;
      align-items: center;
      font-size: 1rem;
      padding-bottom: 0.625rem;
      text-align: left;
      line-height: 1.5;
      font-weight: var(--font-weight-700);
      background-color: var(--color-primary-white);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: auto;
        bottom: 0;
        width: 4rem;
        height: 2px;
        background-color: var(--primary-color);
        transition-property: width;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
      }

      &:hover::after {
        width: 100%;
      }

      :deep(picture) {
        flex: 0 0 60px;

        img {
          height: 100%;
          aspect-ratio: 1;
        }
      }

      span {
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break: break-word;
      }

      &:hover span {
        color: var(--primary-color-hover);
      }
    }
  }

  @media(max-width: 992px) {
    .categories-carousel {
      &__link {
        gap: 0.875rem;
        padding-bottom: 0;

        &::after {
          display: none;
        }

        :deep(picture) {
          height: unset;
          width: 2rem;
          flex: 0 0 auto;

          img {
            height: 100%;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
</style>

