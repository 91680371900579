<script setup>
  import { computed } from 'vue';
  import { route } from '@plenny/visitor';

  const props = defineProps({
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    categories: Array,
  });

  const title = computed(() => {
    if (props.parent) {
      return props.parent.title;
    } else if (props.category) {
      if (props.category._rgt === (props.category._lft + 1)) {
        return null;
      } else {
        return props.category.title;
      }
    } else {
      return null;
    }
  });

  const back = computed(() => {
    if (props.parent) {
      return route('web.blog.category.show', { category: props.parent });
    } else if (props.category) {
      return route('web.blog.post.index');
    } else {
      return null;
    }
  });
</script>
<template>
  <div class="filters-wrapper">
    <div class="block">
      <div class="title">
        <SegmentHeading type="h2" class="filters-title">
          {{ $t('Kategorie') }}
        </SegmentHeading>
      </div>
      <div class="filters-content">
        <VisitorLink v-if="back" :href="back" class="filters-back">
          {{ $t('Wróć do') + ':' }}&nbsp;{{ parent?.title || $t('Wszystkie') }}
        </VisitorLink>
        <ul class="filters-list">
          <li v-for="category in categories" class="filters-item">
            <span class="filters-item-icon">
              <IconLightning />
            </span>
            <VisitorLink :href="route('web.blog.category.show', { category })" class="filters-item-link">
              {{ category.title }}
            </VisitorLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

  .block {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 1.5rem;
    border-radius: 1.125rem;
    line-height: 1.5;
    height: fit-content;
    width: 100%;

    .filters-item {
      display: flex;
      gap: 0.5rem;
    }

    .filters-title {
      font-size: 1.5rem;

      &::after {
        display: none;
      }
    }

    .filters-item-link {
      padding: 0.5rem 0;
    }

    .filters-item-link,
    .filters-back {
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:hover {
        color: var(--primary-color-hover);
      }
    }

    .filters-item-icon {
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
    }

    .title .section-title {
      font-weight: 600;
    }

    .filters-back {
      display: inline-flex;
      padding-bottom: 1rem;
      font-size: 1rem;
      font-weight: var(--font-weight-700);
    }

    .filters-list {
      display: flex;
      flex-direction: column;
    }

    a.active {
      font-weight: var(--font-weight-700);
    }
  }
</style>
