<script setup lang="ts">
  import { useScrollDetector, useScrollWidth } from '$storefront';
  import { onMounted, ref, onBeforeUnmount, watch, computed, type PropType } from 'vue';
  import { route } from '@plenny/visitor';

  type ReviewsSummary = {
    1: { count: number, percent: number },
    2: { count: number, percent: number },
    3: { count: number, percent: number },
    4: { count: number, percent: number },
    5: { count: number, percent: number },
  }

  const props = defineProps({
    breadcrumbs: { type: Array, required: true },
    sections: { type: Array, required: true },
    product: { type: Object, required: true },
    variant: { type: Object, required: true },
    brand: Object,
    category: Object,
    recommended: Array,
    posts: Array,
    page: Object,
    reviews: { type: Object, required: true },
    review_available: { type: Boolean, required: true },
    reviews_summary: { type: Object as PropType<ReviewsSummary>, required: true },
  });

  const scroll = useScrollWidth();

  const variant = ref(props.variant);
  const wrapperHtmlElement = ref();
  const wrapperIsNotIntersecting = ref(false);
  const visible = ref(false);

  const scrollWidth = computed(() => scroll.value + 'px');

  useScrollDetector({
    threshold: 8,
    callback: ({ down }) => {
      if (down) {
        if (wrapperIsNotIntersecting.value) {
          visible.value = true;
        }
      } else {
        visible.value = false;
      }
    },
  });

  let observer;

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        wrapperIsNotIntersecting.value = !entry.isIntersecting;
      });
    }, {
      root: document.body,
      threshold: 0.25,
    });

    observer.observe(wrapperHtmlElement.value);
  });

  onBeforeUnmount(() => {
    observer.disconnect();
  });

  watch(() => variant.value.slug, (value, oldValue) => {
    if (oldValue && value !== oldValue) {
      let loc = route('web.product.show', { slug: value });

      window.history.replaceState(loc, '', loc);
    }
  });

  watch(() => props.variant, (value) => {
    variant.value = value;
  });

</script>
<template>
  <div class="container">
    <SfBreadcrumbs :breadcrumbs class="product-show__breadcrumbs" />
  </div>
  <div :class="{ 'product-banner--visible': visible }" aria-hidden="true" class="product-banner">
    <div class="product-banner__nav">
      <ProductDescriptionNav :product :recommended :sections class="container" />
    </div>
  </div>
  <div ref="wrapperHtmlElement" class="product-details-container">
    <ShopProductViewDetails v-model:variant="variant" :brand="brand" :category="category" :page="page" :product="product" :sections="sections" />
  </div>
  <ShopProductViewDescription :product :recommended :reviews :sections :reviewAvailable="review_available" :reviewsSummary="reviews_summary" />
</template>
<style lang="scss" scoped>

  .product-banner {
    --space: v-bind(scrollWidth);

    position: fixed;
    top: 90px;
    display: flex;
    flex-direction: column;
    right: var(--space);
    left: 0;
    z-index: 10;
    transform: translateY(-120%);
    transition: var(--transition-primary);

    &--visible {
      transform: translateY(0);
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 0.5rem 0;
    }

    &__nav {
      background-color: var(--color-white);
      box-shadow: var(--box-shadow-primary);
    }
  }

  .product-details-container {
    margin-bottom: 4rem;
  }

  @media (max-width: 992px) {
    .product-banner {
      top: 50px;

      &__container {
        padding: 0.625rem;
      }
    }

    .product-details-container {
      margin-bottom: 1.5rem;
    }
  }
</style>

