<script setup>
  import { ref, computed } from 'vue';
  import { $post, route, $delete, useShared } from '@plenny/visitor';
  import { useRealizationTime } from '$storefront';

  const props = defineProps({
    product: Object,
    sections: Array,
    brand: Object,
    category: Object,
    page: Object,
  });

  const photosParameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: false,
    loop: false,
    watchSlidesProgress: true,
  });

  const thumbsParameters = ref({
    slidesPerView: 'auto',
    navigation: true,
    pagination: false,
    loop: false,
    centeredSlidesBounds: true,
    spaceBetween: 10,
  });

  const variant = defineModel('variant', { type: Object, required: false });
  const realization = computed(() => useRealizationTime(variant.value));
  const shared = useShared();

  const isCompared = computed(() => {
    if (variant && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(variant.value.id);
    } else {
      return false;
    }
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': variant.value.id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': variant.value.id }));
    }
  }
</script>
<template>
  <div class="container product-details">
    <div class="product-details__gallery-wrapper">
      <SfProductGallery v-bind="{ thumbsParameters, photosParameters, photos: [variant?.photo, ...product.photos]}" />
    </div>
    <div class="product-details__content">
      <div class="product-details__info">
        <div v-if="product.is_new || (+product.price_gross !== +product.regular_gross)" class="product-details__labels">
          <SfProductTag :visible="product.is_new" tagType="novelty">
            {{ $t('Nowość') }}
          </SfProductTag>
          <SfProductTag :visible="+product.price_gross !== +product.regular_gross" tagType="discount">
            {{ $t('Promocja') }}
          </SfProductTag>
        </div>
        <header class="product-details__header">
          <h1 class="product-details__title" v-html="variant?.title || product.title" />
          <div class="product-details__stars-wishlist">
            <SfStars :count="product.reviews_count" :rating="product.reviews_average" class="product-details__stars" />
            <div class="product-details__wishlist-compare">
              <SfButton v-if="variant" transparent @click="toggleComparator" class="product-details__compare">
                <template #before>
                  <IconArrowDownUp />
                </template>
                <template #default>
                  {{ isCompared ? $t('Usuń z porównania') : $t('Porównaj') }}
                </template>
              </SfButton>
              <SfWishlistButton v-slot="{ saved }" :variant="variant.id">
                <SfButton :class="{ saved }" class="product-details__favorite-btn" transparent>
                  <template #before>
                    <IconHeartFill v-if="saved" />
                    <IconHeart v-else />
                  </template>
                  <template #default>
                    <span v-if="saved">{{ $t('W ulubionych') }}</span>
                    <span v-else>{{ $t('Dodaj do ulubionych') }}</span>
                  </template>
                </SfButton>
              </SfWishlistButton>
            </div>
          </div>
          <p v-if="variant?.description_short || product.description_short" class="product-details__description"
            v-html="variant?.description_short || product.description_short" />
        </header>
        <div class="product-details__actions">
          <ShopProductViewActions v-model:variant="variant" :brand :product="product" />
          <div class="product-details__meta-info">
            <ShopProductViewMeta :brand :category :product :variant />
            <div class="product-details__facts">
              <SfProductStock :variant="variant" class="product-details__stock" />
              <SfProductPoints :variant class="product-details__points" />
              <div class="product-details__realization" v-if="realization">
                <SfIconTruck />
                <p>
                  {{ $t('Szacowany termin realizacji') + ': ' }}
                  <b>{{ realization }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .product-details__gallery-wrapper .items-carousel {
    --sf-carousel-button-border-radius: 0;
    --sf-carousel-button-background: rgba(255, 255, 255, 0.49);
    --sf-carousel-button-color: var(--color-black);
    --sf-carousel-button-color-hover: var(--color-black);
    --sf-carousel-button-border-width: 0;
    --sf-carousel-button-background-hover: var(--color-light-gray);
    --sf-carousel-button-icon-size: 1.5rem;

    --sf-product-gallery-gap: 1rem;
    --sf-product-gallery-thumbs-height: 65px;
    --sf-product-gallery-thumbs-width: 65px;
    --sf-product-gallery-thumbs-border-radius: 0;
    --sf-product-gallery-thumbs-border-style: none;
    --sf-product-gallery-thumbs-border-width: 0;
  }
</style>
<style lang="scss" scoped>
  .product-details {
    display: grid;
    grid-template-columns: 45% 1fr;
    gap: 2.5rem;
    width: 98%;
    background-color: var(--color-primary-white);

    &__gallery-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      width: 100%;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__labels {
      display: flex;
      gap: 1rem;
    }

    &__title {
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: var(--font-weight-700);
    }

    &__description {
      font-size: 0.875rem;
    }

    &__wishlist-compare {
      display: flex;
      gap: 2rem;
    }

    &__stars-wishlist {
      --sf-stars-padding-y: 0;
      --sf-stars-padding-x: 0;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      :deep(.stars__count) {
        margin-top: 0.25rem;
        font-size: 0.75rem;
      }
    }

    &__favorite-btn,
    &__compare {
      --sf-btn-padding-x: 0;
    }

    &__favorite-btn.saved {
      color: var(--primary-color);
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      margin-top: 2rem;
    }

    &__meta-info {
      gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__facts {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    &__points,
    &__stock {
      align-items: flex-start;
    }

    &__realization {
      display: flex;
      gap: 0.5rem;
      font-size: 0.875rem;

      svg {
        font-size: 1.25rem;
      }
    }
  }

  @media (max-width: 1200px) {
    .product-details {
      gap: 2rem;
      padding: 2rem 1rem;
      width: 98%;

      &__info {
        padding-right: 0;
        border-right: 0;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__stars-wishlist {
        flex-wrap: wrap;
      }


      &__meta-info {
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  }

  @media (max-width: 992px) {
    .product-details-container {
      margin-bottom: 1.5rem;

      .product-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0.625rem 1rem;

        &__title {
          font-size: 1rem;
        }

        &__header {
          gap: 1rem;
        }

        &__favorite-btn,
        &__compare {
          --sf-btn-font-size: 0.875rem;
          --sf-btn-font-weight: var(--font-weight-500);
          --sf-btn-padding-y: 0.625rem;
        }

        &__stars {
          --sf-stars-star-size: 1rem;
          --sf-stars-font-size: 0.875rem;
        }

        &__description {
          font-size: 0.75rem;
        }

        &__meta-info {
          gap: 1.5rem;
        }

        &__actions {
          --sf-product-price-presentation-catalog-font-size: 0.75rem;
          --sf-product-price-presentation-current-font-size: 1.5rem;
          --sf-product-price-presentation-gap: 0;
          gap: 2rem;
          margin-top: 1rem;

          :deep(.product-actions) {
            gap: 1.5rem;

            .product-actions__info {
              gap: 1.25rem;
              flex-direction: column;
              align-items: stretch;
            }

            .price--presentation {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .product-details {
      &__realization {
        font-size: 0.75rem;

        svg {
          font-size: 1rem;
        }
      }
    }
  }
</style>
