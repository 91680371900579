<script lang="ts" setup>
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { type: String },
    content: { type: String },
    products: { type: Array },
  });

  const parameters = ref({
    navigation: true,
    spaceBetween: 24,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <Segment :title="header" class="block-slider">
    <div class="block-slider__wrapper">
      <div class="block-slider__content" v-html="content" />
      <SfProductsCarousel :parameters="parameters" :products="products" />
    </div>
  </Segment>
</template>
<style lang="scss" scoped>
  .block-slider {
    &__content {
      padding: 1rem 0;
    }
  }
</style>
