<script setup lang="ts">
  import { useBreakpoints } from '$storefront';
  import { computed, type PropType } from 'vue';
  import type { Photo } from '@plenny/picture';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    header_type: { type: String },
    link: String,
    photos: { type: Array as PropType<Photo[]>, required: true },
  });

  const breakpoints = useBreakpoints();

  const photoType = computed(() => {
    return breakpoints.value.lg ? 'background_desktop' : 'background_mobile';
  });
</script>
<template>
  <section class="block-banner" :class="{'block-banner--backdrop': header}" v-if="photos.length > 0">
    <VisitorLink :href="link" class="block-banner__link">
      <MediaPicture :photo="photos.filter(photo => photo.type === photoType)" :type="photoType" sizes="3840x1300 2x min 993, 1920x650 min 993, 1984x1984 2x, 992x992" />
    </VisitorLink>
    <component v-if="header" :is="header_type || 'h2'" class="block-banner__heading">
      <span>
        {{ header }}
      </span>
    </component>
  </section>
</template>
<style lang="scss">
  .block-banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;

    &--backdrop::after {
      content: '';
      height: 100%;
      width: 70%;
      position: absolute;
      left: 0;
      background: linear-gradient(90deg, var(--color-primary-white) 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }

    &__link {
      width: 100%;
      border: none;
    }

    &__heading {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 98%;
      max-width: 1400px;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      font-size: 2.5rem;
      color: var(--color-black);
      font-weight: var(--font-weight-700);
      z-index: 1;

      span {
        display: inline-flex;
        width: 50%;
      }
    }

    img {
      object-fit: cover;
      aspect-ratio: 1920/650;
    }
  }

  @media(max-width: 992px) {
    .block-banner {
      img {
        aspect-ratio: 1;
      }

      &--backdrop::after {
        height: 50%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(0deg, var(--color-primary-white) 0%, rgba(255, 255, 255, 0) 100%);
      }

      &__heading {
        position: absolute;
        bottom: 5%;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        padding: 0 1rem;
        transform: translate(-50%, 0);

        span {
          width: 100%;
        }
      }
    }
  }

  @media(max-width: 576px) {
    .block-banner__heading {
      font-size: 1.5rem;
    }
  }
</style>
