<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { String, required: true },
    products: { type: Array, required: true },
    header_type: String,
    link: String,
  });

  const parameters = ref({
    navigation: true,
    spaceBetween: 24,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :headerType="header_type" :link>
    <template #icon>
      <IconTag />
    </template>
    <SfProductsCarousel :products :parameters />
  </Segment>
</template>
<style scoped lang="scss">
  .segment {
    background-color: var(--color-light-gray);
    --sf-carousel-slide-padding-x: 0.375rem;
    --sf-carousel-slide-padding-y: 0.375rem;
  }

  @media(max-width: 576px) {
    .segment {
      padding-right: 0;
    }
  }
</style>
