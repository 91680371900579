<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { useShared } from '@plenny/visitor';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Object, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
    products: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const shared = useShared();
  const areFiltersOpen = ref(false);

  const hasProducts = computed(() => props.products.data.length > 0);
  const type = computed(() => shared.value.product_list_mode);
</script>
<template>
  <div class="shop-products">
    <div class="shop-products__filters">
      <SfModal v-if="!breakpoints.lg" v-model:open="areFiltersOpen">
        <SfModalHeader v-model:open="areFiltersOpen">
          {{ $t('Filtry') }}
        </SfModalHeader>
        <ShopProductsFilter class="modal-filters" v-bind="{ categories, brands, price, attributes, options, search }" />
      </SfModal>
      <ShopProductsFilter v-else v-bind="{ categories, brands, price, attributes, options, search }" />
    </div>
    <div class="shop-products__content">
      <section class="shop-products__panel">
        <ShopProductsGridControl @onFiltersOpen="areFiltersOpen = true" class="shop-products__top-panel" />
      </section>
      <section v-if="hasProducts" :class="{ 'shop-products__products--list': type === 1 }" class="shop-products__products">
        <ProductCard v-for="product in products.data" :key="product.id" :horizontal="type === 1" :product="product" class="shop-products__product" />
      </section>
      <section v-else class="shop-products__empty">
        <SfDataPlaceholder>{{ $t('Przepraszamy, nie znaleźliśmy produktów spełniających podane kryteria.') }}</SfDataPlaceholder>
      </section>
      <SfPagination :lastPage="products.last_page" :links="products.links" :currentPage="products.current_page" class="shop-products__pagination" />
    </div>
  </div>
</template>
<style scoped lang="scss">
  .shop-products {
    display: flex;
    align-items: flex-start;
    column-gap: 2rem;
    width: 100%;

    &__filters {
      width: 320px;
      padding-top: 4rem;
      flex: 0 0 320px;
    }

    &__content {
      position: relative;
      padding: 4rem 0 4rem 4rem;
      width: calc(100% - 320px);
      background-color: var(--color-light-gray);

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100vw;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: var(--color-light-gray);
        z-index: -1;
      }
    }

    &__pagination {
      margin-top: 3rem;
    }

    &__products {
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 1fr));
      gap: 2rem;
      margin-top: 2.5rem;

      &.shop-products__products--list {
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }
    }

    &__empty {
      margin-top: 1.5rem;
      padding: 1.5rem;
      background-color: var(--color-white);
    }
  }

  @media (max-width: 1400px) {
    .shop-products {
      gap: 1.5rem;

      &__content {
        padding: 2rem 0 2rem 2rem
      }

      &__products {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
        gap: 1.25rem;
        margin-top: 1.5rem;
      }
    }
  }

  @media (max-width: 1200px) {
    .shop-products__products {
      padding: 0;
      grid-template-columns: repeat(2, minmax(180px, 1fr));
    }
  }

  @media (max-width: 992px) {

    .shop-products {
      flex-direction: column;

      &__filters,
      &__content {
        width: 100%;
      }

      &__content {
        padding: 0
      }

      &__panel {
        padding: 0.75rem 1rem;
      }

      &__top-panel {
        min-height: unset;
      }

      &__filters {
        padding: 0.75rem;
        display: flex;
        justify-content: center;
        flex: 0 1 auto
      }

      &__products {
        gap: 0.625rem;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        padding: 0 1rem;

        &.shop-products__products--list {
          grid-template-columns: 1fr 1fr;
        }
      }

      &__empty {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 576px) {
    .shop-products {

      .shop-products__products {
        grid-template-columns: 1fr;

        &.shop-products__products--list {
          grid-template-columns: 1fr;
        }
      }
    }
  }
</style>
