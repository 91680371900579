<script setup>
  import { useBreakpoints } from '$storefront';
  import { ref } from 'vue';

  const breakpoints = useBreakpoints();

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: Array,
  });

  const parameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: {
      clickable: true,
    },
    loop: true,
  });

</script>
<template>
  <section class="hero-container">
    <SfBanerCarousel :items :parameters class="hero-container__baner" />
  </section>
</template>
<style lang="scss">
  .hero-container {

    &__baner {
      --sf-carousel-button-width: 3.5rem;
      --sf-carousel-button-height: 3.5rem;
      --sf-carousel-slide-padding-x: 0;
      --sf-carousel-slide-padding-y: 0;
    }
  }

  @media(max-width: 992px) {
    .hero-container {
      width: 98%;
      margin: 0 auto;

      &__baner {
        padding: 0;
      }
    }
  }
</style>
