<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    header_type: String,
    posts: Array,
  });

  const parameters = ref({
    navigation: true,
    spaceBetween: 32,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      992: {
        slidesPerView: 2,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :headerType="header_type" :link="route('web.blog.post.index')">
    <template #icon>
      <IconChatLeftQuote />
    </template>
    <SfArticlesCarousel :posts="posts" :parameters class="segment-articles-carousel" />
  </Segment>
</template>
<style scoped lang="scss">
  .segment-articles-carousel {
    --sf-carousel-button-color: var(--color-black);
    --sf-carousel-button-color-hover: var(--color-black);
    --sf-carousel-button-border-width: 0px;
    --sf-carousel-button-background: var(--color-medium-gray);
    --sf-carousel-button-icon-size: 1.25rem;
  }

  @media(max-width: 992px) {
    .segment {
      padding-right: 0;
    }
  }
</style>
