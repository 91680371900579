<script setup>
  import { ref } from 'vue';
  import { useShared } from '@plenny/visitor';

  const props = defineProps({
    product: { type: Object, required: true },
    brand: { type: Object, required: true },
  });

  const variant = defineModel('variant', { type: Object, required: false });
  const shared = useShared();
  const successModalVisible = ref(false);

  const showSuccessModal = () => {
    successModalVisible.value = true;
  };
</script>
<template>
  <div class="product-actions">
    <div class="product-actions__info">
      <div class="product-actions__price">
        <SfProductPrice :price="variant || product" presentation />
        <div class="product-actions__omnibus">
          <SfProductOmnibus :price="variant || product" />
          <SfProductUnitPrice :price="variant || product" />
        </div>
      </div>
      <div class="product-actions__picker">
        <SfVariantPicker v-model:variant="variant" :product="product" class="product-actions__variant-picker" />

        <!-- CartStoreButton emits 'added' event so we can catch in and based on it open/close modal-->
        <SfCartStoreButton :variant="variant" @added="showSuccessModal" class="product-actions__cart-store-button">
          <template #after>
            <IconBasket />
          </template>
        </SfCartStoreButton>
        <CartButtonSuccessModal :open="successModalVisible" :product="product" :variant="variant" @update:open="successModalVisible = $event" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;

    &__info {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 2.5rem;
    }

    &__price {
      --sf-product-price-presentation-current-font-line-height: 1;
      color: var(--color-theme-blue);
    }

    &__omnibus {
      margin-top: 0.5rem;
    }

    &__picker {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-grow: 1;

      :deep(.cart-store-button__button) {
        flex-wrap: wrap;
      }
    }

    &__variant-picker-title {
      font-weight: var(--font-weight-600);
    }

    &__variant-picker {
      margin-top: 0.75rem;
    }
  }

  @media(max-width: 992px) {
    .product-actions {
      &__variant-picker {
        margin-top: 0.5rem
      }

      &__variant-picker-title {
        font-size: 0.875rem
      }

      &__cart-store-button {
        --sf-btn-font-weight: var(--font-weight-500);
      }

      &__omnibus {
        margin-top: 0.125rem;
      }
    }
  }
</style>
