<script setup>
  import { watch, computed, ref } from 'vue';
  import { useScrollWidth, useBreakpoints, useFixedActionsSpacer, useScrollDetector } from '$storefront';
  import { useShared, $delete, route } from '@plenny/visitor';

  const { spacer, offset } = useFixedActionsSpacer();
  const scroll = useScrollWidth();
  const breakpoints = useBreakpoints();
  const shared = useShared();

  const isScrollingDown = ref(true);
  const isScrolled = ref(false);
  const scrollWidth = computed(() => scroll.value + 'px');
  const isComparator = computed(() => Object.keys(shared.value.comparator).length > 0);

  const backToTop = () => {
    document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  function actionChat() {
    window.Tawk_API?.toggle();
  }

  function clearComparator() {
    $delete(route('api.v1.web.comparator.destroy'));
  }

  useScrollDetector({
    threshold: 8,
    callback({ down, scrolled }) {
      isScrollingDown.value = down;
      isScrolled.value = scrolled;
    },
  });

  watch(isScrollingDown, (value) => {
    if (value) {
      spacer.value -= 58;
    } else {
      spacer.value += 58;
    }
  });

</script>
<template>
  <SfButton primary square rounded class="chat fixed-actions-wrapper" @click="actionChat" tabindex="0" :class="{'compare-bar': isComparator }">
    <IconChat />
  </SfButton>
  <SfButton primary square rounded class="back-to-top fixed-actions-wrapper" v-if="isScrolled" @click="backToTop" :class="{'compare-bar': isComparator }">
    <IconChevronUp />
  </SfButton>
  <div class="comparator-bar fixed-actions-wrapper" v-if="isComparator">
    <div class="comparator-bar__container container">
      <div class="comparator-bar__container-inner">
        <div class="comparator-bar__images">
          <MediaPicture sizes="100x100 2x, 50x50, 80x80 2x max 992, 40x40 max 992" v-for="item in shared.comparator" :photo="item.photo" />
        </div>
      </div>
      <SfButton small primary :href="route('web.comparator.index')" class="comparator-bar__compare-btn">
        {{ $t('Porównaj') }}
        <template #after>
          {{ Object.keys(shared.comparator).length }}
        </template>
      </SfButton>
      <SfButton transparent danger square rounded @click="clearComparator">
        <SfIconTrash />
      </SfButton>
    </div>
  </div>
  <div class="bottom-nav-wrapper" v-if="!breakpoints.lg" :class="{ 'is-scrolling-down': isScrollingDown }">
    <BottomNavigation />
  </div>
</template>
<style scoped lang="scss">
  .comparator-bar,
  .back-to-top,
  .chat {
    --bottomOffset: v-bind(offset);
    position: fixed;
    bottom: 0;
    z-index: 9;
  }

  .back-to-top,
  .chat {
    --space: v-bind(scrollWidth);
    cursor: pointer;
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    color: var(--color-black);
    border-radius: 4rem;
    bottom: 2rem;

    &.compare-bar {
      bottom: 76px;
    }
  }

  .back-to-top {
    right: calc(var(--space) + 2rem);
  }

  .chat {
    right: calc(120px + var(--space));
  }

  .comparator-bar {
    --space: v-bind(scrollWidth);
    right: var(--space);
    left: 0;
    height: 60px;
    background-color: var(--color-primary-white);
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow-primary);
    z-index: 10;

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    &__images {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 1rem;

      :deep(picture) {
        flex-grow: 0;
        flex-shrink: 0;

        img {
          aspect-ratio: 1;
        }
      }
    }

    &__compare-btn :deep(.btn__after) {
      background-color: var(--color-theme-blue);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.75rem;
      line-height: 1;
      min-width: 1.25rem;
      padding: 0.25rem 0.375rem;
      border-radius: 1.5rem;
      color: var(--color-primary-white);
      transform: translate(-50%, -50%);
      font-weight: var(--font-weight-400);
    }
  }

  .bottom-nav-wrapper {
    width: 100%;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    height: 58px;
    transform: translateY(0);
    transition: transform 0.3s;
    background-color: var(--color-primary-white);
    box-shadow: var(--box-shadow-primary);
  }

  .bottom-nav-wrapper.is-scrolling-down {
    transform: translateY(58px);
  }

  @media (max-width: 992px) {
    .fixed-actions-wrapper {
      transform: translateY(calc(-1 * var(--bottomOffset)));
      transition: transform 0.3s;
    }

    .comparator-bar {
      &__container {
        padding: 0 0.625rem 0 0;
        gap: 0.625rem;
      }

      &__images {
        gap: 0.5rem;
        flex: 0 0 auto;

        :deep(img) {
          aspect-ratio: 1;
        }
      }

      &__container {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 2rem;
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          z-index: 5;
          pointer-events: none;
        }
      }


      &__container-inner {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .back-to-top,
    .chat {
      font-size: 1.25rem;
      width: 50px;
      height: 50px;
      bottom: 1rem;
    }

    .back-to-top {
      right: calc(var(--space) + 1rem);
    }

    .chat {
      right: calc(80px + var(--space));
    }
  }
</style>
