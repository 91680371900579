<script setup lang="ts">
  import IconHeart from '../Icons/IconHeart.vue';
  import { useSession, route } from '@plenny/visitor';

  const session = useSession();
</script>
<template>
  <div class="action-item">
    <VisitorLink :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="link">
      <IconHeart clss="wishlist-header-icon" />
    </VisitorLink>
  </div>
</template>
<style scoped lang="scss">
  .action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.scroll {
      .link {
        column-gap: 0;
      }
    }

    .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.375rem;
      font-size: 1.5rem;
      height: 100%;
      color: var(--color-main-black);
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:hover {
        color: var(--primary-color-hover);
      }

    }
  }
</style>
