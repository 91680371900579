<script setup>
  import { ref } from 'vue';
  import { useShared } from '@plenny/visitor';

  const shared = useShared();
  const current = ref(undefined);
  const category = ref();
  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const closeDrawer = function() {
    open.value = false;
  };
</script>

<template>
  <SfDrawer v-model:open="open" class="menu-drawer" left>
    <SfDrawerHeader v-model:open="open" class="menu-drawer__header">
      {{ $t('Menu') }}
    </SfDrawerHeader>
    <div class="menu-drawer__body">
      <div class="menu-drawer__wrapper">
        <ul class="menu-drawer__content">
          <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" class="menu-drawer__item" @close="closeDrawer" />
        </ul>
      </div>
    </div>
  </SfDrawer>
</template>
<style lang="scss" scoped>
  .menu-drawer {
    --sf-drawer-header-font-size: 1.25rem;
    --sf-drawer-header-gap: 0.75rem;
    --sf-drawer-header-line-height: 2.375rem;
    --sf-drawer-header-padding-y: 0.75rem;
    --sf-drawer-header-padding-x: 1rem;
    --sf-drawer-header-background: var(--color-light-gray);

    --sf-drawer-padding-x: 0;
    --sf-drawer-padding-y: 0;
    --sf-drawer-gap-y: 0;

    &__wrapper {
      font-weight: var(--font-weight-500);
      padding: 0.75rem 1rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 2rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      list-style: none;

      :deep(li.nav-item) {
        a {
          padding: 0.875rem 0;
        }

        .nav-item-arrow {
          display: none;
        }

        .children-wrapper {
          position: static;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          box-shadow: none;
          padding: 0 0 0 1rem;

          .children {
            display: flex;
            flex-direction: column;
          }
        }

        &:hover .children-wrapper {
          border: none;
        }
      }
    }
  }
</style>
