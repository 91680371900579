<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
    headerType: { type: String, required: false },
  });
</script>
<template>
  <section class="segment">
    <div class="container">
      <header class="segment__header" v-if="title || link ">
        <SegmentHeading v-if="title" :type="headerType || 'h2'">
          {{ title }}
          <template #icon>
            <slot name="icon"></slot>
          </template>
        </SegmentHeading>
        <SfButton v-if="link" transparent class="segment__btn" :to="link" :small="!breakpoints.md">
          <template #default>
            {{ $t('Zobacz więcej') }}
          </template>
          <template #after>
            <IconChevronRight />
          </template>
        </SfButton>
      </header>
      <div class="segment__content">
        <slot />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .segment {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 100px 0;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__content {
      margin-top: 2.5rem;
    }
  }

  @media(max-width: 992px) {
    .segment {
      padding: 2rem 1rem;

      &__content {
        margin-top: 1.5rem;
      }
    }
  }
</style>
