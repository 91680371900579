<script setup lang="ts">
  import { ref, onMounted, type PropType, type Ref, onBeforeUnmount, onUpdated } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import { useBreakpoints } from '$storefront';
  import { route } from '@plenny/visitor';

  type Product = {
    title: string,
    variant_id: number,
    photo: string,
    price_gross: string,
    regular_gross: string,
    regular_net: string;
    price_net: string;
    omnibus_gross: string;
    omnibus_net: string;
    slug: string;
  }

  const breakpoints = useBreakpoints();

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: { String, required: true },
    products: { type: Array as PropType<Product[]>, required: true },
    product_header_type: String,
    header_type: String,
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    if (props.products.length > 0) {
      swiper.value = new Swiper(htmlElement.value, {
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 1500,
        autoplay: {
          delay: 8000,
          pauseOnMouseEnter: true,
        },
        modules: [Navigation],
        navigation: {
          nextEl: htmlNavigation.value.next.htmlElement,
          prevEl: htmlNavigation.value.prev.htmlElement,
        },
      });
    }
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });

  function target(product: Product) {
    return route('web.product.show', { slug: product.slug });
  }
</script>
<template>
  <section class="items-carousel special-offer" v-if="products.length > 0">
    <IconPatch class="special-offer__icon" />
    <div class="container">
      <div
        ref="htmlElement"
        class="swiper items-carousel__container special-offer__container"
      >
        <div class="swiper-wrapper">
          <div v-for="product in products" class="swiper-slide items-carousel__slide special-offer__slide">
            <header class="special-offer__header">
              <component v-if="header" :is="header_type || 'h2'" class="special-offer__heading">{{ header }}</component>
              <component v-if="product.title" :is="product_header_type || 'h3'" class="special-offer__title">
                <VisitorLink :href="target(product)">
                  {{ product.title }}
                </VisitorLink>
              </component>
            </header>
            <div class="special-offer__content">
              <div class="special-offer__image-wrapper">
                <VisitorLink :href="target(product)">
                  <MediaPicture :photo="product.photo" sizes="1072x690 2x, 536x345" class="special-offer__image" />
                  <div class="swiper-lazy-preloader"></div>
                </VisitorLink>
              </div>
              <div class="special-offer__price">
                <SfProductPrice :price="product" class="special-offer__product-price" />
                <SfProductOmnibus :price="product" class="special-offer__omnibus" />
              </div>
              <div class="special-offer__buttons-wrapper">
                <SfWishlistButton :variant="product.variant_id" class="special-offer__widhlist" tabindex="0" />
                <SfCartButton :product="product">
                  <SfButton :square="!breakpoints.lg" primary class="special-offer__buy-btn">
                    <template #after>
                      <IconBasket class="special-offer__buy-btn-icon" />
                    </template>
                    <template #default>
                      {{ $t('Dodaj do koszyka') }}
                    </template>
                  </SfButton>
                </SfCartButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SfCarouselButtons ref="htmlNavigation" />
    </div>
  </section>
</template>
<style scoped lang="scss">

  .special-offer {
    --sf-carousel-button-space: -1rem;
    --sf-carousel-button-prev-translateX: -100%;
    --sf-carousel-button-next-translateX: 100%;
    --sf-carousel-button-background: rgba(255, 255, 255, 0.50);
    --sf-carousel-button-width: 3.5rem;
    --sf-carousel-button-height: 3.5rem;
    position: relative;
    overflow: hidden;
    padding: 170px 0;

    .container {
      position: relative;
    }

    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      width: 100%;
    }

    &__title {
      text-align: center;
      padding: 0 2rem;

      a {
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);

        @media(hover: hover) {
          &:hover {
            color: var(--primary-color-hover);
          }
        }

        &:focus {
          color: var(--primary-color-hover);
        }
      }
    }

    &__heading {
      font-size: 4rem;
      line-height: 1;
    }

    &__slide {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 2rem;

      :deep(img) {
        max-width: 536px;
        aspect-ratio: 536/345;
      }
    }

    &__buttons-wrapper {
      width: auto;
      grid-column: 2;
      justify-self: center;
      align-self: center;
      display: flex;
      gap: 1rem;
    }

    &__image-wrapper {
      text-align: center;
    }

    &__price {
      --sf-product-price-current-font-size: 4rem;
      --sf-product-price-current-font-line-height: 1.1;
      --sf-product-price-per-color: var(--color-black);
      --sf-product-price-per-font-size: 1.125rem;
      --sf-product-price-per-font-line-height: 1.2;
      --sf-product-price-catalog-font-size: 1.5rem;
      --sf-product-price-catalog-font-weight: var(--font-weight-400);
      --sf-omnibus-color-text: var(--color-black);
      --sf-omnibus-font-size: 0.875rem;

      color: var(--color-white);
      order: -1;
    }

    &__icon {
      position: absolute;
      left: 0;
      top: 50%;
      font-size: 1500px;
      transform: translate(-50%, -50%);
      color: var(--primary-color);
      z-index: -1;

      @media(max-width: 1800px) {
        font-size: 100vw;
      }
    }
  }

  @media(max-width: 1600px) {
    .special-offer {
      --sf-carousel-button-space: 0;
      --sf-carousel-button-prev-translateX: 0;

      :deep(.items-carousel__button) {
        top: unset;
        bottom: 0;
        transform: translate(0, 0);

        &.items-carousel__button--next {
          right: unset;
          left: calc(var(--sf-carousel-button-width) + 0.5rem);
        }
      }
    }
  }

  @media(max-width: 992px) {
    .special-offer {
      padding: 2rem 0;

      .container {
        padding: 0 1.5rem;
      }

      &__header {
        grid-template-columns: 1fr;
        gap: 3rem;
        text-align: center;
        flex-grow: 1;
      }

      &__title {
        font-size: 1rem;
        padding: 0;

        a:focus {
          color: var(--color-black);
        }
      }

      &__heading {
        font-size: 2rem;
      }

      &__slide {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        gap: 2rem;

        :deep(img) {
          max-width: unset;
          aspect-ratio: 536/345;
        }
      }

      &__buttons-wrapper {
        width: auto;
        justify-self: center;
        align-self: unset;
        display: flex;
        gap: 1rem;
      }

      &__buy-btn {
        flex-grow: 1;
      }

      &__image-wrapper {
        text-align: center;
        width: 100%;
        order: -1;
      }

      &__price {
        --sf-product-price-current-font-size: 1.5rem;
        --sf-product-price-current-font-line-height: 1.5;
        --sf-product-price-per-color: var(--color-black);
        --sf-product-price-per-font-size: 0.875rem;
        --sf-product-price-per-font-line-height: 1.2;
        --sf-product-price-catalog-font-size: 1rem;
        --sf-omnibus-color-text: var(--color-black);
        --sf-omnibus-font-size: 0.75rem;

        color: var(--color-black);
        order: -1;
      }

      &__icon {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -70%);
        font-size: 150vw;

        @media(max-width: 567px) {
          top: -50px;
        }
      }
    }
  }
</style>
