<script setup lang="ts">
  import CategoriesContainer from './CategoriesContainer.vue';
  import { ref } from 'vue';
  import IconList from '../Icons/IconList.vue';

  defineProps({
    compact: { type: Boolean, required: false, default: false },
  });

  const htmlButton = ref();
  const active = ref(false);

  function show() {
    active.value = true;
  }

  // When hiding the categories dropdown, we want to focus back on categories
  // button, which will make sure we get focus back at the place where we
  // started.
  function hide() {
    active.value = false;
    htmlButton.value.focus();
  }

  function toggle() {
    if (active.value) {
      hide();
    } else {
      show();
    }
  }

  // When clicking on the button, we want to toggle dropdown on or off.
  // This will handle touch devices where click event is transpiled into touch
  // event.
  function onClick() {
    toggle();
  }

  // For proper accessibility we can toggle the dropdown with space or enter
  // keys, this will let toggle the dropdown when using the website with
  // keyboard only.
  function onSpaceOrEnterKeydown() {
    toggle();
  }

  // We have to check for device hover abilities. We want to react for hover
  // only when a device input device is able to hover. Otherwise, it will
  // run on non-hover devices just before click event, causing the dropdown to
  // hide on the first click.
  function onMouseEnter() {
    if (window.matchMedia('(hover: hover)').matches) {
      show();
    }
  }

  // On desktop devices we want to hide the dropdown when mouse leaves
  // a dropdown or categories button.
  function onMouseLeave() {
    hide();
  }

  // For accessibility, we allow users to hide the dropdown by pressing the
  // escape key. This will focus back on categories button.
  function onEscapeKeydown() {
    hide();
  }

  // Close is triggered when a category link is clicked within the navigation
  // dropdown. This will close the dropdown, since we're within an SPA, the
  // navigation dropdown would keep open between pages loads.
  function onClose() {
    hide();
  }
</script>
<template>
  <div class="categories-wrapper" :class="{ 'categories-wrapper--active': active }" @keydown.esc="onEscapeKeydown" @mouseleave="onMouseLeave">
    <div ref="htmlButton" id="categories-btn" class="categories-btn" :class="{ 'categories-btn--compact': compact }" role="button" tabindex="0" aria-controls="categories-nav" @mouseenter="onMouseEnter" @keydown.enter.space.prevent="onSpaceOrEnterKeydown" @click.prevent.stop="onClick">
      <div class="categories-btn__icon">
        <IconList />
      </div>
      <div class="categories-btn__text">
        {{ $t('Kategorie') }}
      </div>
    </div>
    <CategoriesContainer id="categories-nav" :aria-hidden="!active" @close="onClose" />
  </div>
</template>
<style scoped lang="scss">
  .categories-wrapper {
    display: contents;

    .cat {
      display: none;
    }

    &--active {
      .cat {
        display: block;
      }
    }
  }

  .categories-btn {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    height: 100%;
    padding: 35px 30px;
    z-index: 999;

    &--compact {
      padding: 1rem 1rem;
    }

    &__icon {
      display: flex;
      font-size: 1.125rem;
      line-height: 20px;
    }

    &__text {
      font-size: 1rem;
      font-weight: var(--font-weight-700);
    }
  }
</style>
